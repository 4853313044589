.center-body {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  /****** center box
  width: 300px;
  height: 300px;
  border: solid 1px #aaa; */
}
.loader-square-9 {
  width: 50px;
  height: 50px;
  color: #ffffff;
  display: inline-block;
  position: relative;
}
.loader-square-9::before {
  position: absolute;
  width: 15px;
  height: 15px;
  display: block;
  content: "";
  background: currentColor;
  transform-origin: 1rem 1rem;
  animation: move 1s infinite ease-in-out;
}
@keyframes move {
  0% {
      transform: translateX(0);
      box-shadow: 25px 0 0, 25px 25px 0, 0 25px 0;
  }
  100% {
      transform: translateX(25px);
      box-shadow: 0 25px 0, -25px 25px 0, -25px 0 0;
  }
}
