* {
  margin: 0;
  padding: 0;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  font-family: 'Roboto', sans-serif !important;
  letter-spacing: 0.2px;
}

.stop-edit svg {
  background: red;
}

.icon-spinner {
  animation: spin-animation 1.5s infinite;
  display: inline-block;
}

@keyframes spin-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}

/* Layout */

#map {
  width: 100%;
  height: 100%;
}

.app-header.ant-layout-header {
  padding: 0 22px;
  color: #fff;
  display: flex;
  height: 56px;
  line-height: 56px;
}

.app-header-title {
  font-size: 14px;
  font-weight: 500;
  margin-left: 32px;
}

.mapboxgl-map,
.maplibregl-map {
  font-family: 'Roboto', Helvetica Neue, Arial, Helvetica, sans-serif;
}
.maplibregl-ctrl-top-left {
  padding-right: 450px;
}

.maplibregl-ctrl-top-left .maplibregl-ctrl {
  margin-top: 8px;
  margin-left: 8px;
}

.maplibregl-ctrl-top-right{
   display: flex;
   align-items: start;
   z-index: 3;
    /* for scroll in chart-list with dynamic height*/
   height: 100%;
   pointer-events: none;
}

.maplibregl-ctrl-top-right .maplibregl-ctrl {
  margin-top: 8px;
  margin-right: 8px;
}
.maplibregl-ctrl-bottom-left .maplibregl-ctrl {
  margin-bottom: 8px;
  margin-left: 8px;
}
.maplibregl-ctrl-bottom-right .maplibregl-ctrl {
  margin-bottom: 8px;
  margin-right: 8px;
}

.maplibregl-ctrl-group:not(:empty) {
  box-shadow: none;
}

.maplibregl-ctrl-icon {
  padding: 3px;
}
.maplibregl-ctrl-attrib-inner {
  color: #212121;
}
.maplibregl-ctrl button.maplibregl-ctrl-zoom-in .maplibregl-ctrl-icon,
.maplibregl-ctrl button.maplibregl-ctrl-zoom-out .maplibregl-ctrl-icon {
  display: none;
}

.maplibregl-ctrl button.maplibregl-ctrl-zoom-in::after {
  content: '+';
  font-size: 14px;
  line-height: 29px;
  font-family: 'Roboto';
  font-weight: 300;
  font-size: 24px;
  vertical-align: middle;
}
.maplibregl-ctrl button.maplibregl-ctrl-zoom-out::after {
  content: '–';
  font-size: 14px;
  line-height: 29px;
  font-family: 'Roboto';
  font-weight: 300;
  font-size: 24px;
  vertical-align: middle;
}

.maplibregl-popup-anchor-bottom .maplibregl-popup-tip{
  position: relative;
  top: -1px;
}

.mapboxgl-ctrl button.ant-btn-default:not(:disabled):hover, .maplibregl-ctrl button.ant-btn-default:not(:disabled):hover{
  background-color: white;
}

/* Sidebar menu */
.sidebar-menu .ant-menu-item {
  width: 70px !important;
  height: 70px !important;
  line-height: 1 !important;
  margin: 0 !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  padding-left: 5px !important;
  padding-right: 8px !important;
  align-items: center;
  font-size: 10px !important;
  border-left: 3px solid transparent !important;
  font-weight: 500 !important;
  border-radius: 0;
  white-space: normal;
  text-align: center;
  border-inline-end-color: #e5ecf5;
}

.sidebar-menu .ant-menu-title-content {
  margin-left: 0 !important;
  margin-top: 8px !important;
  line-height: 1.2;
  user-select: none;
}

.sidebar-menu .ant-menu-item .ant-menu-item-icon {
  font-size: 24px !important;
  user-select: none;
}

.sidebar-menu .ant-menu-item-selected {
  border-left-color: #177ddc !important;
  background-color: transparent;
}

.app-sidebar-menu__item.active:before{
  content: '';
  position: absolute;
  inset: 0;
  background-color: #e4edf2;
}

.app-sidebar-menu__item.active > *,
.ant-menu-item-icon{
  position: relative;
  z-index: 1;
}

.sidebar-menu .ant-menu-item a::before{
  z-index: 2;
}


.identify-result-card {
  min-width: 240px;
  padding: 12px;
  line-height: 1.2;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.16);
}

.identify-result-card__label {
  margin-bottom: 12px;
}

.identify-result-card__value {
  font-size: 40px;
  line-height: 1;
  letter-spacing: 0;
}

.identify-result-card__circle {
  position: relative;
  margin-right: 2px;
  margin-left: -2px;
}

.identify-result-card__circle-1 {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  opacity: 0.24;
}

.identify-result-card__circle-2 {
  position: absolute;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  margin-top: -6px;
  margin-left: -6px;
}

.ant-spin.light .ant-spin-dot-item {
  background-color: white;
}

.ant-btn {
  text-transform: uppercase;
  letter-spacing: .2px;
  font-size: 12px;
  font-weight: 500;
  box-shadow: none;
}

.ant-btn > span,
.ant-btn > .ant-btn-icon{
  vertical-align: middle;
}
.ant-btn-default:not(.ant-btn-icon-only) > .ant-btn-icon{
  font-size: 16px;
  margin-top: -1px;
}

.ant-btn:not(.ant-btn-icon-only)>.ant-btn-icon:not(:last-child){
  margin-inline-end: 4px;
}

label {
  font-weight: 500;
}

.ant-divider {
  border-block-start-color: #e5ecf5;
}

.ant-select-dropdown {
  padding: 0;
  border-radius: 0;
  border: 1px solid #e5ecf5;
}

.ant-select-dropdown .ant-select-item {
  border-radius: 0;
  font-size: 12px;
  min-height: 32px;
}


.ant-select:hover .ant-select-selector,
.ant-select-focused .ant-select-selector,
.ant-picker:hover,
.ant-picker-focused {
  border-color: #1e2027 !important;
  box-shadow: none !important;
}

.ant-select-item-option-selected {
  background-color: #fafafa !important;
}

.ant-slider .ant-slider-track,
.ant-slider:hover .ant-slider-track {
  background-color: #3371b2;
}

.ant-slider .ant-slider-handle::after {
  box-shadow: 0 0 0 2px #3371b2;
}

.ant-slider .ant-slider-dot-active {
  border-color: #3371b2;
}

.ant-slider .ant-slider-mark-text,
.ant-slider .ant-slider-mark-text-active {
  color: #8692a2;
}

.app-slider--fill-reversed .ant-slider-rail,
.app-slider--fill-reversed:hover .ant-slider-rail {
  background-color: #3371b2;
}
.app-slider--fill-reversed .ant-slider-track,
.app-slider--fill-reversed:hover .ant-slider-track {
  background-color: #e5ecf5;
}

.app-slider--fill-reversed .ant-slider-dot,
.app-slider--fill-reversed:hover .ant-slider-dot {
  border-color: #3371b2;
}
.app-slider--fill-reversed .ant-slider-dot-active,
.app-slider--fill-reversed:hover .ant-slider-dot-active {
  border-color: #e5ecf5;
}

.lte-sectors-control-off {
  opacity: 0.5;
}

.text-muted {
  color: #8692a2;
}

.text-sm{
  font-size: 12px;
}
.text-xs{
  font-size: 10px;
}

.font-weight-regular{
  font-weight: 400 !important;
}

.circle{
  border-radius: 50%;
}
.nowrap{
  white-space: nowrap;
}

.ant-radio-wrapper .ant-radio-checked .ant-radio-inner{
  background-color: #fff;
}

.ant-radio-wrapper .ant-radio-inner::after{
  background-color: #3371b2;
}

.chart-map-control {
  margin-top: 8px;
  max-height: calc(100% - 16px);
  overflow-y: auto;
  overflow-x: hidden;
}

.chart-map-control::-webkit-scrollbar{
  width: 14px;
  background-color: transparent;
}

.chart-map-control::-webkit-scrollbar-track{
  background-color: transparent;
}

.chart-map-control::-webkit-scrollbar-thumb{
  background-color: #fff;
  border-left: 6px solid transparent;
  background-clip: padding-box;
}

@-moz-document url-prefix() {
  .chart-map-control {
     scrollbar-gutter: stable;
     scrollbar-color: #bebebe transparent;
  }
}

@media not all and (min-resolution:.001dpcm) {
  @supports (-webkit-appearance:none) {
    .chart-map-control{
      width: 344px;
    }
  }
}

.ant-collapse>.ant-collapse-item >.ant-collapse-header{
  align-items: center;
}

.cursor-pointer{
  cursor: pointer;
}

p{
  margin-bottom: 1em;
}

strong, b{
  font-weight: 500;
}

hr{
  border:0; 
  height: 1px; 
}

.table-noborder{
  border: 0; 
  border-collapse:collapse; 
  border-spacing:0; 
}