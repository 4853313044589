.my-poi-map-draw-control .poi-btn {
  color: #3371B2;
}

.my-poi-map-draw-control .poi-btn.active {
  color: #FFFFFF;
  background-color: #3371B2;
}
.my-poi-map-draw-control .poi-btn.active:hover {
  color: #FFFFFF;
  background-color: #3371B2;
}

.my-poi-map-draw-control .delete-all-block {
  color: red;
  display: inline-block;
  position: absolute;
  margin-top: -3px;
  padding-right: 6px;
  left: 35px;
  width: 89px;
  height: 28px;
  line-height: 28px;
  background-color: #f5f5f5;
  border-radius: 0px;
  cursor: pointer;
  border-radius: 4px;
}

.my-poi-map-draw-control .delete-all-block:hover {
  background-color: #f2f2f2;
}

.poi-btn--draw {
    border-radius: 4px 4px 0 0;
}
